import React from 'react'
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux';
import { RouterProvider, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import LoadingIndicator from './components/loadingIndicator';
import './index.css'
import { store } from './redux/store';
import { router } from './router';
import './i18n/config';

Sentry.init({
	dsn: "__SENTRY_DSN__",
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
	],
	tracesSampleRate: 0.4,
	tracePropagationTargets: ["localhost", /^https:\/\/api\.acna\.cat\/api/],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
});


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<Provider store={store}>
			<RouterProvider router={router} />
			<LoadingIndicator />
		</Provider>
	</React.StrictMode>,
)
