import { useEffect, useState } from 'react'
import axios from 'axios'
import { MapContainer, WMSTileLayer, TileLayer, Marker, LayersControl, Popup } from "react-leaflet";
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { ArrowDownTrayIcon, EyeIcon } from '@heroicons/react/24/outline'
import { trackPromise } from 'react-promise-tracker';
import moment from 'moment';
import qs from "qs"

import Endpoints from '../../lib/endpoints';
import { Accident } from '../../models/accident';
import { Season } from '../../models/season';
import { mapLayers } from '../../components/map/layers';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { HTTP } from '../../lib/http/client';

export default function AccidentsData() {
	const { t, i18n } = useTranslation()
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams()

	const userState = useSelector((state: RootState) => state.user)

	const [accidents, setAccidents] = useState<Accident[]>([])
	const [temporada, setTemporada] = useState("")
	const [seasons, setSeasons] = useState<Season[]>([])

	const columns: GridColDef[] = [
		{ field: 'date', headerName: `${t("accidents.models.date")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'place', headerName: `${t("accidents.models.place")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'danger_degree', headerName: `${t("accidents.models.danger_degree")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'desencadenant', headerName: `${t("accidents.models.desencadenant")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'origen', headerName: `${t("accidents.models.origen")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'size', headerName: `${t("accidents.models.size")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'members', headerName: `${t("accidents.models.members")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'dragged', headerName: `${t("accidents.models.dragged")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'damaged', headerName: `${t("accidents.models.damaged")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'dead', headerName: `${t("accidents.models.dead")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{ field: 'activitat', headerName: `${t("accidents.models.activitat")}`, headerClassName: "text-xs", filterable: false, headerAlign: 'center', align: 'center', cellClassName: () => "text-xs" },
		{
			field: 'view', headerName: `${t("accidents.models.actions")}`, headerClassName: "text-xs", renderCell: (params) => (
				<button onClick={() => navigate(`/accidents/${params.id}`)}
					className="text-blue-600 hover:text-blue-900">
					<EyeIcon className="h-5 w-5 text-gray-400 hover:text-blue-900" aria-hidden="true" />
				</button>
			),
			filterable: false
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (searchParams.get('season')) {
					setTemporada(searchParams.get('season')!)
				} else {
					const response = await axios.get(Endpoints.config + "?populate=*")
					setTemporada(response.data.data.temporadaActual.name)
				}

				const query = qs.stringify({
					sort: ['name:desc']
				}, {
					encodeValuesOnly: true,
				});
				const response = await axios.get(Endpoints.seasons + `?${query}`)
				setSeasons(response.data.data)
			} catch (error) {
				console.error(error)
			}
		}

		trackPromise(fetchData())
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (temporada) {
					const query = qs.stringify({
						filters: {
							temporada: {
								name: {
									$eq: temporada,
								}
							},
						},
						sort: ['date:desc'],
						populate: '*'
					}, {
						encodeValuesOnly: true,
					});

					const response = await axios.get(Endpoints.accidents + `?${query}`)
					console.log(response.data)
					setAccidents(response.data.data)
				} else {
					const query = qs.stringify({
						sort: ['date:desc'],
						populate: '*'
					}, {
						encodeValuesOnly: true,
					});
					const response = await axios.get(Endpoints.accidents + `?${query}`)
					console.log(response.data)
					setAccidents(response.data.data)
				}

			} catch (error) {
				console.error(error)
			}
		}

		trackPromise(fetchData())
	}, [temporada])

	const rows: GridRowsProp = accidents.map((accident) => (
		{
			id: accident.id,
			date: (accident.date ? moment(accident.date).format("DD/MM/YYYY") : ""),
			place: (accident.place ? accident.place : ""),
			danger_degree: (accident.danger_degree ? (i18n.language === "es" ? (accident.danger_degree.name_es ?? accident.danger_degree.name) : accident.danger_degree.name) : t("accidents.models.unknown")),
			desencadenant: (accident.desencadenant ? `${(i18n.language === "es" ? (accident.desencadenant.name_es ?? accident.desencadenant.name) : accident.desencadenant.name)} (${accident.desencadenant.code})` : t("accidents.models.unknown")),
			origen: (accident.origen ? (i18n.language === "es" ? (accident.origen.name_es ?? accident.origen.name) : accident.origen.name) : t("accidents.models.unknown")),
			size: (accident.size ? accident.size : t("accidents.models.unknown")),
			members: (accident.members !== null ? (accident.members !== -1 ? accident.members : t("accidents.models.unknown")) : t("accidents.models.unknown")),
			dragged: (accident.dragged !== null ? (accident.dragged !== -1 ? accident.dragged : t("accidents.models.unknown")) : t("accidents.models.unknown")),
			damaged: (accident.damaged !== null ? (accident.damaged !== -1 ? accident.damaged : t("accidents.models.unknown")) : t("accidents.models.unknown")),
			dead: (accident.dead !== null ? (accident.dead !== -1 ? accident.dead : t("accidents.models.unknown")) : t("accidents.models.unknown")),
			activitat: (accident.activitat ? (i18n.language === "es" ? (accident.activitat.name_es ?? accident.activitat.name) : accident.activitat.name) : t("accidents.models.unknown")),
		}));

	return (
		<div className="flex flex-col p-5 grow space-y-5 w-full">
			{!userState.loggedIn && <MapContainer center={[43.003069514915786, -0.9930763932215235]} zoom={7} scrollWheelZoom={false} className="w-full max-h-96 h-full z-0">
				<LayersControl position="topright">
					{
						mapLayers.map((layer) => {
							switch (layer.type) {
								case "wms":
									return (
										<LayersControl.BaseLayer checked={layer.checked} name={layer.name} key={layer.name}>
											<WMSTileLayer
												url={layer.url}
												layers={layer.layer}
												format={layer.format}
												attribution={layer.attribution}
											/>
										</LayersControl.BaseLayer>
									)
								case "tile":
									return (
										<LayersControl.BaseLayer checked={layer.checked} name={layer.name} key={layer.name}>
											<TileLayer
												attribution={layer.attribution}
												url={layer.url}
											/>
										</LayersControl.BaseLayer>
									)
								default:
									return (<>Error</>)
							}
						})
					}
				</LayersControl>

				{
					accidents.map((accident) => (
						<Marker key={accident.id} position={[accident.coordinates.latitude, accident.coordinates.longitude]}
							eventHandlers={{
								click: () => {
									navigate(`/accidents/${accident.id}`)
								},
								mouseover: (e) => {
									e.target.openPopup();
								}
							}} >
							<Popup>
								<dl className="flex flex-col">
									<div className="">
										<div className='text-lg font-semibold'>{accident.place}</div>
										<dt className="text-sm font-medium text-gray-500">{t("accidents.models.date")}</dt>
										<dd className="mt-1 text-sm text-gray-900">{accident.date}</dd>
									</div>
									{
										accident.scope && <div className="">
											<dt className="text-sm font-medium text-gray-500">{t("accidents.models.scope")}</dt>
											<dd className="mt-1 text-sm text-gray-900">{accident.scope.name}</dd>
										</div>
									}
									<div className="w-44 h-auto rounded-lg">
										{
											(accident.gallery && accident.gallery.length > 0 &&
												<img src={accident.gallery[0].formats.small.url} alt={accident.gallery[0].name} className="object-cover rounded-lg" />
											)
										}
									</div>
									<div className='mt-2'>
										<Link
											to={`/accidents/${accident.id}`}
										>
											<button type='button' className="rounded-md bg-blue-600 py-1.5 px-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
												{t("accidents.see_details")}
											</button>
										</Link>
									</div>
								</dl>
							</Popup>
						</Marker>
					))
				}
			</MapContainer>}
			<div className='grow'>
				<div className="w-1/4 mb-2 flex items-center space-x-4">
					<select
						id="temporada"
						name="temporada"
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
						onChange={(e) => {
							setTemporada(e.target.value)
							setSearchParams({ season: e.target.value })
						}}
						value={temporada}
					>
						<option value="">{t("accidents.form.select.season")}</option>
						{seasons.map((temp) => <option key={temp.name} value={temp.name}>{temp.name}</option>)}
					</select>
					{
						userState.loggedIn && <div>
							<button
								id="export_clients"
								type="button"
								className="inline-flex items-center p-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={() => {
									const fetchFile = async () => {
										const response = await HTTP.get(Endpoints.temporadaCSV + `?season=${temporada}`,
											{ headers: { Accept: 'text/csv' }, responseType: 'blob' })
										const href = URL.createObjectURL(response.data);

										const link = document.createElement('a');
										link.href = href;
										link.setAttribute('download', `accidents_${temporada}.csv`);
										document.body.appendChild(link);
										link.click();

										// clean up "a" element & remove ObjectURL
										document.body.removeChild(link);
										URL.revokeObjectURL(href);
									}

									trackPromise(fetchFile())
								}}
							>
								<ArrowDownTrayIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					}
				</div>
				<div className="h-full">
					<DataGrid rows={rows} columns={columns} />
				</div>
			</div>
		</div>
	)
}
