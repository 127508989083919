import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useFormik } from 'formik';
import axios from 'axios';
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../assets/images/logo-acna.svg'
import { setSessionStorage } from '../lib/http/cookies'
import Endpoints from '../lib/endpoints';
import { setUser } from '../redux/userSlice';

export default function LogIn() {
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [openAlert, setOpenAlert] = useState(false)

	const cancelButtonRef = useRef(null)

	useEffect(() => {
		const lang = searchParams.get('lang')
		if (lang) {
			console.info("LANG: ", lang)
			i18n.changeLanguage(lang);
		}
	}, [])

	const formik = useFormik({
		initialValues: {
			identifier: '',
			password: ''
		},
		onSubmit: (values) => {
			const submitData = async () => {
				try {
					const response = await axios.post(Endpoints.authLogin, values)
					setSessionStorage('token', response.data.jwt)
					dispatch(setUser(response.data.user))
					navigate("/")
				} catch (error) {
					console.error(error)
					setOpenAlert(true)
				}
			}

			trackPromise(submitData())
		}
	});

	return (
		<>
			<div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-md w-full space-y-8">
					<div>
						<img
							className="mx-auto h-20 w-auto"
							src={Logo}
							alt="ACNA"
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t("login.welcome")}</h2>
					</div>
					<form className="mt-8 space-y-6" onSubmit={formik.handleSubmit}>
						<input type="hidden" name="remember" defaultValue="true" />
						<div className="rounded-md shadow-sm -space-y-px">
							<div>
								<label htmlFor="identifier" className="sr-only">
									{t("login.username")}
								</label>
								<input
									id="identifier"
									name="identifier"
									type="text"
									required
									className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
									placeholder="Usuari"
									onChange={formik.handleChange}
									value={formik.values.identifier}
								/>
							</div>
							<div>
								<label htmlFor="password" className="sr-only">
									{t("login.password")}
								</label>
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
									placeholder="Contrasenya"
									onChange={formik.handleChange}
									value={formik.values.password}
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
							>
								<span className="absolute left-0 inset-y-0 flex items-center pl-3">
									<LockClosedIcon className="h-5 w-5 text-blue-500 group-hover:text-blue-400" aria-hidden="true" />
								</span>
								{t("login.login")}
							</button>
						</div>
					</form>
				</div>
			</div>
			{/* Modal alert */}
			<Transition.Root show={openAlert} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpenAlert}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											{t("login.error.title")}
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												{t("login.error.description")}
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex flex-row-reverse">
									<button
										type="button"
										className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => setOpenAlert(false)}
										ref={cancelButtonRef}
									>
										{t("login.error.button")}
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	)
}
