// https://www.freecodecamp.org/news/how-to-add-localization-to-your-react-app/
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import CAT from './locales/cat/translations.json'
import ES from './locales/es/translations.json'

i18n.use(initReactI18next).init({
    fallbackLng: 'ca',
    lng: 'ca',
    resources: {
        ca: {
            translations: CAT
        },
        es: {
            translations: ES
        },
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['es', 'ca'];

export default i18n;