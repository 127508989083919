import { Fragment } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { deleteSessionStorageFor } from '../lib/http/cookies'
import Logo from '../assets/images/logo-acna.svg'
import { logOutUser } from '../redux/userSlice'
import { RootState } from '../redux/store'

const userNavigation = [
	{ name: 'Ajustaments', href: '/settings' },
	{ name: 'Tancar la sessió', href: 'signout' },
]

export default function TopBar() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const userState = useSelector((state: RootState) => state.user)

	return (
		<>
			{/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
			<Popover
				as="header"
				className={({ open }) =>
					classNames(
						open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
						'bg-white shadow-sm lg:static lg:overflow-y-visible'
					)
				}
			>
				{({ open }) => (
					<>
						<div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
							<div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
								<div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
									<div className="shrink-0 flex items-center">
										<Link to="/">
											<img
												className="block h-12 w-auto"
												src={Logo}
												alt="ACNA"
											/>
										</Link>
									</div>
								</div>
								<div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
									<div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
										<div className="w-full">

										</div>
									</div>
								</div>
								<div className="flex items-center md:absolute md:right-0 md:inset-y-0 lg:hidden">
									{/* Mobile menu button */}
									<Popover.Button className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
										<span className="sr-only">Open menu</span>
										{open ? (
											<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
										) : (
											<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
										)}
									</Popover.Button>
								</div>
								<div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
									{/* Profile dropdown */}
									<Menu as="div" className="shrink-0 relative ml-5">
										<div>
											<Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
												<span className="sr-only">Open user menu</span>
												<UserCircleIcon className="h-8 w-8 text-blue-800" />
											</Menu.Button>
										</div>
										<Transition
											as={Fragment}
											enter="transition ease-out duration-100"
											enterFrom="transform opacity-0 scale-95"
											enterTo="transform opacity-100 scale-100"
											leave="transition ease-in duration-75"
											leaveFrom="transform opacity-100 scale-100"
											leaveTo="transform opacity-0 scale-95"
										>
											<Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none divide-y">
												<div className='block py-2 px-4'>
													<div className='text-sm'>
														{userState.user.username}
													</div>
													<div className='text-lg font-semibold'>
														{userState.user.email}
													</div>
												</div>
												<div>
													{userNavigation.map((item) => {
														if (item.href === 'signout') {
															return (
																<Menu.Item key={item.name}>
																	{({ active }) => (
																		<button
																			onClick={() => {
																				deleteSessionStorageFor('token')
																				dispatch(logOutUser())
																			}}
																			className={classNames(
																				active ? 'bg-gray-100' : '',
																				'block py-2 px-4 text-sm text-gray-700 w-full text-left'
																			)}
																		>
																			{t(`layout.menu.${item.href.replace("/", "")}`)}
																		</button>
																	)}
																</Menu.Item>
															)
														} else {
															return (
																<Menu.Item key={item.name}>
																	{({ active }) => (
																		<a
																			href={item.href}
																			className={classNames(
																				active ? 'bg-gray-100' : '',
																				'block py-2 px-4 text-sm text-gray-700'
																			)}
																		>
																			{t(`layout.menu.${item.href.replace("/", "")}`)}
																		</a>
																	)}
																</Menu.Item>
															)
														}
													})}
												</div>
											</Menu.Items>
										</Transition>
									</Menu>
									{
										!location.pathname.includes("add") && !location.pathname.includes("edit") && !location.pathname.includes("accidents") && <div>
											<Link
												to="/accidents/add"
												className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
											>
												{t("layout.new_accident")}
											</Link>
										</div>
									}
								</div>
							</div>
						</div>

						<Popover.Panel as="nav" className="lg:hidden" aria-label="Global">
							{
								!location.pathname.includes("add") && !location.pathname.includes("edit") && !location.pathname.includes("accidents") &&
								<div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
									<Link
										to="/accidents/add"
										className="ml-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
									>
										{t("layout.new_accident")}
									</Link>
								</div>
							}
							<div className="border-t border-gray-200 pt-4 pb-3">
								<div className="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
									<div className="shrink-0">
										<UserCircleIcon className="h-8 w-8 text-blue-800" />
									</div>
								</div>
								<div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
									{userNavigation.map((item) => {
										if (item.href === 'signout') {
											return (<button
												key={item.name}
												onClick={() => {
													deleteSessionStorageFor('token')
													dispatch(logOutUser())
												}}
												className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
											>
												{t(`layout.menu.${item.href.replace("/", "")}`)}
											</button>)
										} else {
											return (<a
												key={item.name}
												href={item.href}
												className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
											>
												{t(`layout.menu.${item.href.replace("/", "")}`)}
											</a>)
										}
									})}
								</div>
							</div>
						</Popover.Panel>
					</>
				)}
			</Popover>
		</>
	)
}
