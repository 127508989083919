import axios, { AxiosInstance } from 'axios';
import { getSessionStorageFor } from './cookies';

export const API_URL = import.meta.env.REACT_APP_API_URL;

export function createClient(): AxiosInstance {
    // console.log(API_URL)
    return axios.create({
        baseURL: API_URL,
        headers: {
            Authorization: `Bearer ${getSessionStorageFor('token')}`,
            'Content-Type': 'application/json',
        },
    });
}

export const HTTP = createClient();

HTTP.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${getSessionStorageFor('token')}`;
        return config;
    },
    (error) => Promise.reject(error),
);