import "proj4leaflet";
import Leaflet from 'leaflet'

const crs25831 = new Leaflet.Proj.CRS('EPSG:25831', '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
	{
		resolutions: [1100, 550, 275, 75, 50, 25, 10, 5, 2, 1, 0.5, 0.25]
	}
);

export const mapLayers = [
	{
		type: "tile",
		name: "OSM",
		url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
		attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
		checked: true,
	},
	{
		type: "tile",
		name: "Satèl·lit",
		url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
		attribution: 'Powered by Esri | Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
		checked: false,
	},
	{
		type: "wms",
		name: "Topogràfic (ICGC)",
		url: "https://geoserveis.icgc.cat/icc_mapesmultibase/utm/wms/service?",
		layer: "topo",
		attribution: 'Institut Cartogràfic i Geològic de Catalunya',
		format: 'image/jpeg',
		continuousWorld: true,
		checked: false,
		crs: crs25831
	},
	/*
	{
		type: "wms",
		name: "Topogràfic (IGN)",
		url: "https://www.ign.es/wms-inspire/ign-base?SERVICE=WMS&",
		layer: "OI.OrthoimageCoverage",
		attribution: "PNOA WMS. Cedido por © Instituto Geográfico Nacional de España",
		format: 'image/jpeg',
		continuousWorld: true,
		checked: false,
	},*/
]